import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserNotFoundComponent } from './user-not-found.component';
import { DevComponent } from './dev.component';

const routes: Routes = [
  { path: 'pages', loadChildren: './pages/pages.module#PagesModule' },
  { path: 'cms', loadChildren: './cms/cms.module#CmsModule' },
  { path: 'user-not-found', component: UserNotFoundComponent },
  { path: 'dev', component: DevComponent },
  { path: '', redirectTo: 'pages/profile', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/profile' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
