import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { BitrixRestAPIService } from './services/bitrix-rest-api.service';
import { WgUserService } from './services/wg-user.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    //BitrixRestAPIService
    //{provide: BitrixRestAPIService, useClass: BitrixRestAPIService }
  ]
})
export class WgCoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: WgCoreModule,
      providers: [
        //{provide: BitrixRestAPIService, useClass: BitrixRestAPIService }
        { provide: WgUserService, useClass: WgUserService }
      ]
    };
  }
}
