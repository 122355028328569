import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE} from '@angular/material/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WgCoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserNotFoundComponent } from './user-not-found.component';
import { DevComponent } from './dev.component';


@NgModule({
  declarations: [
    AppComponent,
    UserNotFoundComponent,
    DevComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    WgCoreModule.forRoot()
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    { provide: APP_BASE_HREF, useValue: '/' },
    /*{
        provide: APP_BASE_HREF,
        useValue: window.location.pathname.substr(0, window.location.pathname.lastIndexOf('/')+1)
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
