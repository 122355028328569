import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PeriodService {

  constructor() {}

  getPeriod(): number {
    let date = new Date();
    let month = date.getMonth() + 1;

    if(month == 12 || (month >= 1 && month <= 5) ) {
      return 1;
    }

    if(month >= 6 && month <= 11) {
      return 2;
    }

  }

  getNumMonthInPeriod(month: number) {

    if(month == 12) {
      return 1;
    }

    if(month >= 1 && month <= 5) {
      return month + 1;
    }

    if(month >= 6 && month <= 11) {
      return month - 5;
    }
  }

}
