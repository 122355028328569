import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  protected comands: BehaviorSubject<String> = new BehaviorSubject(null);

  constructor() { }

  resize() {
    this.comands.next('resize');
  }

  onChangeResize (): Observable<String> {
    return this.comands
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
