export class BitrixInitAppData {
  DOMAIN: string;
	PROTOCOL: number;
	APP_SID: string;
	PATH: string;
	LANG: string;
	AUTH_ID: string;
	REFRESH_ID: string;
	MEMBER_ID: string;
	PLACEMENT: string;
	IS_ADMIN: boolean;
	AUTH_EXPIRES: number;
	USER_OPTIONS: Array<any>;
	APP_OPTIONS: Array<any>;
  PLACEMENT_OPTIONS: Array<any>;
  FIRST_RUN: boolean;
  INSTALL: boolean;
}
