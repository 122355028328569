import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ElementRef, Renderer } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Headers } from '@angular/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError, share, filter } from 'rxjs/operators';

import { BitrixInitAppData } from './../models/bitrix-init-app-data';


export class Util{

  typeIsString(item: any) {
      return item === '' ? true : (item ? (typeof (item) == "string" || item instanceof String) : false);
  }

  typeIsNotEmptyStrinig(item: any) {
      return this.typeIsString(item) ? item.length > 0 : false;
  }

  typeIsBoolean(item: any) {
      return item === true || item === false;
  }
  typeIsNumber(item: any) {
      return item === 0 ? true : (item ? (typeof (item) == "number" || item instanceof Number) : false);
  }
  typeIsFunction(item: any) {
      return item === null ? false : (typeof (item) == "function" || item instanceof Function);
  }
  typeIsElementNode(item: any) {
      return item && typeof (item) == "object" && "nodeType" in item && item.nodeType == 1 && item.tagName && item.tagName.toUpperCase() != 'SCRIPT' && item.tagName.toUpperCase() != 'STYLE' && item.tagName.toUpperCase() != 'LINK';
  }
  typeIsDomNode(item: any) {
      return item && typeof (item) == "object" && "nodeType" in item;
  }
  typeIsArray(item: any) {
      return item && Object.prototype.toString.call(item) == "[object Array]";
  }
  typeIsDate(item: any) {
      return item && Object.prototype.toString.call(item) == "[object Date]";
  }
}

@Injectable({
  providedIn: 'root'
})
export class BitrixRestAPIService {

    private params: BitrixInitAppData;
    private util: Util = new Util();
    private charsList: string = '0123456789abcdefghijklmnopqrstuvwxyz';
    private sendMessageCallbacks: Object = {};
    private initList: Function[] = [];

    protected initStatus$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private renderer: Renderer2

    constructor(rendererFactory: RendererFactory2, private http: HttpClient) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.params = new BitrixInitAppData();
        this.params.PROTOCOL = 1;
        this.params.AUTH_EXPIRES = 0;

        if(!!window.name)
        {
            let q: string[] = window.name.split('|');
            this.params.DOMAIN = q[0].replace(/\:(80|443)$/, '');
            this.params.PROTOCOL = parseInt(q[1])||0;
            this.params.APP_SID = q[2];
        }

        this.addListenGlobal('window', 'message', (event) => {
            this.sendMessageRunCallback(event);
        });

        this.sendMessage('getInitData', {}, (params)=>{

            this.params.APP_OPTIONS = params.APP_OPTIONS;
            this.params.AUTH_EXPIRES = params.AUTH_EXPIRES;
            this.params.AUTH_ID = params.AUTH_ID;
            this.params.FIRST_RUN = params.FIRST_RUN;
            this.params.INSTALL = params.INSTALL;
            this.params.IS_ADMIN = params.IS_ADMIN;
            this.params.LANG = params.LANG;
            this.params.MEMBER_ID = params.MEMBER_ID;
            this.params.PATH = params.PATH;
            this.params.PLACEMENT = params.PLACEMENT;
            this.params.PLACEMENT_OPTIONS = params.PLACEMENT_OPTIONS;
            this.params.REFRESH_ID = params.REFRESH_ID;
            this.params.USER_OPTIONS = params.USER_OPTIONS;

            this.initStatus$.next(true);

           /* let fn, i = 0;
            while (this.initList && (fn = this.initList[i++]))
            {
                fn.apply(window);
            }*/
        });
    }

    onInit (): Observable<boolean> {
      return this.initStatus$.pipe(
        filter(value => !!value),
        share()
      );
    }

    addListenGlobal(target: string, name: string, callback): Function {
        return this.renderer.listen(target, name, callback);
    }

    init(callback: Function): void {
        this.initList.push(callback);
    }

    getParams(): BitrixInitAppData {
        return this.params;
    }

    selecUser(title: string, cb: Function): void {
        this.sendMessage('selectUser', {title: title, mult:false}, cb);
    }

    selecUsers(title: string, cb: Function): void {
        this.sendMessage('selectUser', {title: title, mult:true}, cb);
    }

    selectCRM(params: any, cb: Function): void {
         this.sendMessage('selectCRM', {
            entityType: params.entityType,
            multiple: params.multiple,
            value: params.value
        }, cb);
    }

    selectAccess(params: any, cb: Function): void {
        this.sendMessage('selectAccess', {
           title: params.title,
           value: params.value
       }, cb);
    }

    resizeWindow(width: number, height: number, cb: Function) {
        if(width > 0 && height > 0)
        {
            this.sendMessage('resizeWindow', {width:width,height:height}, cb);
        }
    };

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
      console.error(`Rest Api Error: ${message}`);
    }

    callMethod(method: string, params: any): Observable<any> {

        let url = 'http'+(this.params.PROTOCOL?'s':'')+'://' + this.params.DOMAIN + this.params.PATH + '/' + this.ajaxEscape(method) + '.json?auth=' + this.params.AUTH_ID;

        if(!!params)
        {
          const body = JSON.stringify(params);

          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json;charset=utf-8'
            })
          };

          return this.http.post(url, body, httpOptions).pipe(
            catchError(this.handleError('Error in request', []))
          );

        }
        else
        {
          this.handleError('No params set', []);
        }
    }

    /*
     * @param calls = {call_id:{method:method,params:params},...};
     */
    callBatch(calls, bHaltOnError?): Observable<any> {

      let data = {};
      for(let i in calls) {
        data[i] = calls[i].method+'?'+this.ajaxPrepareData(calls[i].params, '');
      }

      return this.callMethod('batch', {halt:!!bHaltOnError?1:0, cmd:data});
    }



    sendMessage(cmd: string, params: {}, cb) {

        cmd += ':' + (!!params ? JSON.stringify(params) : '')
                + ':' + this.sendMessageSetCallback(cb)
                + (!!this.params.APP_SID ? (':' + this.params.APP_SID) : '');

        parent.postMessage(cmd, 'http'+(this.params.PROTOCOL?'s':'')+'://' + this.params.DOMAIN);
    }

    sendMessageSetCallback(cb): string {

        let cbId: string = '';
        if(!!cb)
        {
            cbId = this.utilUniqid();
            this.sendMessageCallbacks[cbId] = cb;
        }

        return cbId;
    }

    sendMessageRunCallback(e): void {

        e = e || window.event;

        if(e.origin != 'http'+(this.params.PROTOCOL?'s':'')+'://'+this.params.DOMAIN)
            return;

        if(!!e.data)
        {
            let cmd: string[] = this.utilSplit(e.data, ':'),
                args: any = [];

            if(!!this.sendMessageCallbacks[cmd[0]])
            {
                if(!!cmd[1])
                    args = JSON.parse(cmd[1]);

                this.sendMessageCallbacks[cmd[0]].apply(window, [args]);
            }
        }

    }

    utilUniqid(): string {
		let s: string = '';
		for (let i = 0; i <32; i++)
			s += this.charsList[Math.round(Math.random()*(this.charsList.length-1))];
		return s;
    }

    utilSplit(s: string, ss): Array<string> {
		let r = s.split(ss);
		return [r[0],r.slice(1).join(ss)];
    }

    ajaxEscape(str: string): string {
        return encodeURIComponent(str);
    }

    ajaxPrepareData(arData: any, prefix: string) {
        var data = '', objects = [];

        if (this.util.typeIsString(arData) || arData == null)
        {
            return arData||'';
        }
        else
        {
            for(var i in arData)
            {
                if (!arData.hasOwnProperty(i))
                {
                    continue;
                }

                var name = this.ajaxEscape(i);

                if(prefix)
                    name = prefix + '[' + name + ']';

                if(typeof arData[i] == 'object')
                {
                    objects.push([name,arData[i]]);
                }
                else
                {
                    if (data.length > 0)
                    {
                        data += '&';
                    }

                    data += name + '=' + this.ajaxEscape(arData[i])
                }
            }

            var cnt = objects.length;
            if(cnt > 0)
            {
                var cb = function(str)
                {
                    data += (!!str ? '&' : '') + str;
                    if(--cnt <= 0)
                    {
                        return data;
                    }
                    return false;
                }

                var cnt1 = cnt;
                for(let i = 0; i < cnt1; i++)
                {
                    if(this.util.typeIsDomNode(objects[i][1]))
                    {
                        if(objects[i][1].tagName.toUpperCase() == 'INPUT' && objects[i][1].type == 'file')
                        {
                            if(this.fileReaderCanUse())
                            {
                                this.fileReader(objects[i][1], ((name)=>{
                                    return (result) => {
                                        if(this.util.typeIsArray(result)&&result.length>0)
                                        {
                                            let res = cb(name + '[0]=' + this.ajaxEscape(result[0]) + '&' + name + '[1]=' + this.ajaxEscape(result[1]));
                                            if( res !== false ) return res;
                                        }
                                        else
                                        {
                                            let res = cb(name+'=');
                                            if( res !== false ) return res;
                                        }
                                    }
                                })(objects[i][0]));
                            }
                        }
                        else if(typeof objects[i][1].value != 'undefined')
                        {
                            let res = cb(objects[i][0] + '=' + this.ajaxEscape(objects[i][1].value));
                            if( res !== false ) return res;
                        }
                        else
                        {
                            let res = cb('');
                            if( res !== false ) return res;
                        }
                    }
                    else if(this.util.typeIsDate(objects[i][1]))
                    {
                        let res = cb(objects[i][0] + '=' + this.ajaxEscape(objects[i][1].toJSON()));
                        if( res !== false ) return res;
                    }
                    else if(this.util.typeIsArray(objects[i][1]) && objects[i][1].length <= 0)
                    {
                        let res =cb(objects[i][0] + '=');
                        if( res !== false ) return res;
                    }
                    else
                    {
                      data += '&'+this.ajaxPrepareData(objects[i][1], objects[i][0]);
                    }
                }

                return data;
            }
            else
            {
              return data;
            }
        }
    }

    fileReader(fileInput: any, cb: Function) {
        if(this.fileReaderCanUse())
        {
            var files = fileInput.files,
                len = 0,
                result = fileInput.multiple ? [] : null;

            for (var i = 0, f; f = files[i]; i++)
            {
                var reader = new window['FileReader'];

                reader.BXFILENAME = files[i].name;

                reader.onload = function(e){
                    e = e||window.event;

                    var res = [this.BXFILENAME,btoa(e.target.result)];

                    if(result === null)
                        result = res;
                    else
                        result.push(res);

                    if(--len <= 0)
                    {
                        cb(result);
                    }
                };

                reader.readAsBinaryString(f);
            }
            len = i;
            if(len <= 0)
            {
                cb(result);
            }
        }
    };

    fileReaderCanUse() {
        return !!window['FileReader'];
    }

}
