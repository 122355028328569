import { Injectable } from '@angular/core';
import { WgUser } from './../models/user';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WgUserService {

  protected user$: BehaviorSubject<WgUser> = new BehaviorSubject(null);
  private _user: WgUser = null;

  constructor() { }

  addUser (user: WgUser) {
    this._user = user;
    this.user$.next(user);
  }

  getUser() {
    return this._user;
  }

  onChangeUser (): Observable<WgUser> {
    return this.user$
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
