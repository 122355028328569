import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { PeriodService } from './period.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter, map } from 'rxjs/operators';
import { WgResult } from "./../models/result";

@Injectable({
  providedIn: 'root'
})
export class UsedCoinsService {

  private _usedCoinsStorage: string = 'used_coins';
  private _userStorage: string = 'user';
  private _result: BehaviorSubject<WgResult> = new BehaviorSubject(null);

  private _userId: number = 0;
  private _coins: number = 0;

  constructor(
    private api: BitrixRestAPIService,
    private period: PeriodService
  ) {
  }

  _addCoins(idAccount, summ) {
    let s = this.api.callMethod('entity.item.update', {
      ENTITY: this._usedCoinsStorage,
      ID: idAccount,
      PROPERTY_VALUES: {
        QUANTITY: summ,
        PERIOD: this.period.getPeriod(),
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      let msg = `Счет пользователя с id ${this._userId} успешно обновлен`;
      if(!res)
        msg = `Счет пользователя с id ${this._userId} не обновлен`

      this._result.next({
        succes: res,
        message: msg
      });
      s.unsubscribe();
    });
  }

  _createUsedCoinsAccount(userApp) {
    let s = this.api.callMethod('entity.item.add', {
      ENTITY: this._usedCoinsStorage,
      NAME: `${userApp.NAME} ${this._userId}`,
      PROPERTY_VALUES: {
        ID_USER: userApp.ID,
        ID_USER_B24: userApp.PROPERTY_VALUES.ID_USER_B24,
        QUANTITY: 0,
        PERIOD: this.period.getPeriod(),
      },
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((id)=>{
      this._addCoins(id, this._coins);
      s.unsubscribe();
    });
  }

  _getUserById() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._userStorage,
      FILTER: {ID: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      if(res.length == 0) {
        this._result.next({
          succes: false,
          message: `Возникла системная ошибка [4]. Пользователь с id ${this._userId} не найден`
        });
      } else {
        this._createUsedCoinsAccount(res[0]);
      }
      s.unsubscribe();
    });
  }

  _getUsedCoinsAccount() {
    let s = this.api.callMethod('entity.item.get', {
      ENTITY: this._usedCoinsStorage,
      FILTER: {PROPERTY_ID_USER: this._userId}
    }).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
       if(res.length == 0) {
        this._getUserById();
       } else {
          let coins = parseInt(res[0].PROPERTY_VALUES.QUANTITY) + this._coins;

          if(res[0].PROPERTY_VALUES.PERIOD != this.period.getPeriod()) {
            coins = this._coins;
          }

          this._addCoins(res[0].ID, coins);
       }
       s.unsubscribe();
    });
  }

  _clear() {
    this._result.next(null);
  }

  add(user: number, coins: number): Observable<WgResult> {

    this._clear();
    this._userId = user;
    this._coins = coins;

    this._getUsedCoinsAccount();

    return this._result
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
