import { Injectable } from '@angular/core';
import { BitrixRestAPIService } from './bitrix-rest-api.service';
import { IHistoryData } from "./../models/i-history-data";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private api: BitrixRestAPIService
  ) { }

  addTransaction(data: IHistoryData, type: string) {
    let params = {
      ENTITY: 'history',
      NAME: data.name,
      PROPERTY_VALUES: {
        USER_FROM_ID: data.from.id,
        USER_FROM_ID_B24: data.from.id_b24,
        USER_FROM_TITLE: data.from.name,

        USER_TO_ID: data.to.id,
        USER_TO_ID_B24: data.to.id_b24,
        USER_TO_TITLE: data.to.name,
        USER_TO_COEFFICIENT: data.to.coefficient,

        ID_HONOR_TYPE: data.honor_type,
        ID_NOMINATION: data.nomination,
        ID_NOMINATION_TITLE: data.name,
        COMMENT: data.comment,

        QUANTITY: data.quantity,
        TYPE: type,
      },
    };

    return this.api.callMethod('entity.item.add', params);
  }
}
