import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculateWorkExperience {

  dateDiff(datepart, fromdate, todate) {
    datepart = datepart.toLowerCase();
    var diff = todate - fromdate;
    var divideBy = { w:604800000,
                     d:86400000,
                     h:3600000,
                     n:60000,
                     s:1000 };

    return Math.floor( diff/divideBy[datepart]);
  }

  calculate(date: string) {
    let cof = 1;
    if(date != '') {
      let currentDate = new Date();
      let split = date.split('.').reverse();
      let expDate = new Date(split.join('.'));
      let years = this.dateDiff('d', expDate, currentDate)/365;

      if( years >= 5 ) {
        cof = 1.5;
      } else if(years >= 3) {
        cof = 1.3;
      }
    }
    return cof;
  }
}
