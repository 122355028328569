import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { share, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinerService {

  protected comands: BehaviorSubject<String> = new BehaviorSubject(null);

  constructor() { }

  show() {
    this.comands.next('show');
  }

  hide() {
    this.comands.next('hide');
  }

  onChangeSpiner (): Observable<String> {
    return this.comands
            .pipe(
              filter(value => !!value),
              share()
            );
  }
}
