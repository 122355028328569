import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wg-user-not-found',
  template: `
  <div class="container-fluid">
    <div class="row">
        <div class="col">
          <div class="alert alert-danger" role="alert">
            Ваш пользователь не найден, обратитесь к администратору.
          </div>
        </div>
    </div>
  </div>
  `,
})
export class UserNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
