import { Component, OnInit } from '@angular/core';
import { BitrixRestAPIService, WgUserService, CalculateWorkExperience } from '@core/services';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: []
})
export class AppComponent implements OnInit {
  title = 'Dolphin';
  private _userStorage: string = 'user';

  constructor(
    private api: BitrixRestAPIService,
    private userService: WgUserService,
    private router: Router,
    private workExperience: CalculateWorkExperience
  ) {
  }

  findUserInApp(user) {

    let batch = [];

    batch.push({
        method: 'entity.item.get',
        params: {
          ENTITY: this._userStorage,
          FILTER: {
            PROPERTY_ID_USER_B24: user.ID
          }
        }
    });

    batch.push({
        method: 'entity.item.get',
        params: {
          ENTITY: 'moderator',
          FILTER: {
            PROPERTY_ID_USER: user.ID
          }
        }
    });

    let s = this.api.callBatch(batch).pipe(
      map( ({ result }) => result )
    ).subscribe((res)=>{
      let u = res.result[0][0];
      let role = res.result[1][0];

      if(!u) {
        this.router.navigate(['/user-not-found']);
      } else {

        this.userService.addUser({
          id: parseInt(u.ID),
          id_b24: parseInt(u.PROPERTY_VALUES.ID_USER_B24),
          name: u.NAME,
          photo: u.PREVIEW_PICTURE,
          department: parseInt(u.PROPERTY_VALUES.DEPARTMENT),
          title_department: u.PROPERTY_VALUES.TITLE_DEPARTMENT,
          position: u.PROPERTY_VALUES.POSITION || '',
          role: role ? role.PROPERTY_VALUES.ROLE : '',
          type_honor: parseInt(u.PROPERTY_VALUES.TYPE_HONOR) || 0,
          experience: u.PROPERTY_VALUES.EXPERIENCE || '',
          coefficient: this.workExperience.calculate(u.PROPERTY_VALUES.EXPERIENCE),
          count_coins: parseInt(u.PROPERTY_VALUES.COUTN_COINS) || 0,
        });
      }
    });
  }

  getUser() {
    this.api.callMethod('user.current',{})
      .pipe(
        map( ({ result }) => result )
      )
      .subscribe(response => {
        this.findUserInApp(response);
      });
  }

  ngOnInit() {
    this.api.onInit().subscribe( status => {
      if(status) {
        this.getUser();
      }
    });
  }
}
